import type { FC } from "react";
import { useState, useContext, useEffect } from "react";
import { EditorContext } from "../App";
import { ItemList } from "./Item-list";
import "../scss/EditorRight.scss";
import { ArrowBackIos } from "@mui/icons-material";
import { Tab, Tabs } from "react-bootstrap";
import { backgroundTracks } from "../constants/backgroundTracks";
import { UploadImages } from "./UploadImages";

export const EditorRight: FC<{
  titles: string[];
  alternate_texts: string[];
  images: { src: string; height: number; width: number }[];
}> = ({ titles, alternate_texts, images }) => {
  const editor_context = useContext(EditorContext);
  const proposition = editor_context.propositions[editor_context.selected];
  const [mode, setMode] = useState("default"); // default or view all
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [type, setType] = useState("backgrounds");
  const [selectedSlide, setSelecedSlide] = useState(
    proposition?.slides && proposition?.slides.length > proposition?.currentSlide ? proposition.slides[proposition.currentSlide] : undefined
  );

  const onChangeMode = (mode: string, type: string) => {
    setMode(mode);
    setType(type);
    // console.log("onChangeMode", mode, type);
  };

  useEffect(() => {
    setSelecedSlide(proposition?.slides && proposition?.slides.length > proposition?.currentSlide ? proposition?.slides[proposition?.currentSlide] : undefined);
  }, [editor_context, proposition?.currentSlide, proposition?.slides]);

  return (
    <>
      {mode === "see_all" && (
        <div className="inner-all">
          <div className="see-all-block">
            <div className="inner-see-all">
              <div className="container-all">
                <div
                  className="controls"
                  onClick={() => {
                    onChangeMode("default", "");
                  }}
                >
                  <ArrowBackIos sx={{ color: "rgb(232, 236, 241)", fontSize: "28px" }} />
                  {(type === "images" || type === "stock") && <span>Backgrounds</span>}
                  {type === "music" && <span>Musics</span>}
                  {(type === "text" || type === "title") && <span>Text</span>}
                </div>
                {type === "images" && <UploadImages />}
                <div className="tab mt-4">
                  {(type === "images" || type === "stock") && (
                    <Tabs defaultActiveKey={type} id="fill-tab-example" className="mb-3" fill>
                      <Tab eventKey="images" title="your page">
                        <ItemList onChangeMode={onChangeMode} type="images" format="vertical" items={images} />
                      </Tab>
                      <Tab eventKey="stock" title="Stock images">
                        <ItemList onChangeMode={onChangeMode} type="images" format="vertical" items={(selectedSlide?.associateStockPhoto || []) as any[]} />
                      </Tab>
                      {/* <Tab eventKey="ai-images" title="AI Generated">
                        <ItemList onChangeMode={onChangeMode} type="images" format="vertical" items={[]} />
                      </Tab> */}
                    </Tabs>
                  )}
                  {(type === "text" || type === "title") && (
                    <Tabs defaultActiveKey={type} id="fill-tab-example" className="mb-3" fill>
                      <Tab eventKey="text" title="Alternate text ideas">
                        <ItemList onChangeMode={onChangeMode} type="text" format="vertical" items={alternate_texts} />
                      </Tab>
                      <Tab eventKey="title" title="Titles from your page">
                        <ItemList onChangeMode={onChangeMode} type="text" format="vertical" items={titles} />
                      </Tab>
                    </Tabs>
                  )}
                  {type === "music" && (
                    <Tabs defaultActiveKey={type} id="fill-tab-example" className="mb-3" fill>
                      <Tab eventKey="music" title="Our music">
                        <ItemList onChangeMode={onChangeMode} type="music" format="vertical" items={backgroundTracks} />
                      </Tab>
                      {/* <Tab eventKey="stock music" title="Stock music">
                        <ItemList onChangeMode={onChangeMode} type="music" format="vertical" items={[]} />
                      </Tab> */}
                      {/* <Tab eventKey="ai-music" title="AI Generated">
                        <ItemList onChangeMode={onChangeMode} type="music" format="vertical" items={[]} />
                      </Tab> */}
                    </Tabs>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {mode === "default" && (
        <div className="inner">
          <div className="container-list">
            <div className="one-list">
              <ItemList onChangeMode={onChangeMode} type="images" format="horizontal" items={images.slice(0, 10)} />
            </div>
            <div className="one-list">
              <ItemList onChangeMode={onChangeMode} type="stock" format="horizontal" items={((selectedSlide?.associateStockPhoto || []) as any[]).slice(0, 10)} />
            </div>
            <div className="one-list">
              <ItemList onChangeMode={onChangeMode} type="text" format="horizontal" items={alternate_texts.slice(0, 10)} />
            </div>
            <div className="one-list">
              <ItemList onChangeMode={onChangeMode} type="title" format="horizontal" items={titles.slice(0, 10)} />
            </div>
            <div className="one-list">
              <ItemList onChangeMode={onChangeMode} type="music" format="horizontal" items={backgroundTracks} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
