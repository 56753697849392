import { FC, useState, useEffect } from "react";
import Lottie from "lottie-react";
import loading from "../lottie/loading.json";
import "../scss/Loading.scss";

import { getAnalytics, logEvent } from "firebase/analytics";
const analytics = getAnalytics();

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

const quotes = [
  {
    quote: "93% of buyers report that video is important in building trust in a brand.",
    author: "– MarketingCharts.com",
  },
  {
    quote: "74% of TikTok users said TikTok has helped them decide what to buy after seeing it advertised or promoted on the platform.",
    author: "– Tiktok",
  },
  {
    quote:
      "We have found that videos exponentially drive clicks over images when it comes to Facebook ads. […] We have found our clients to have 20 to 30% more conversions when utilizing video over images when it comes to Facebook ads",
    author: "– Chantelle Stevenson, ClearPivot",
  },
  {
    quote: "On average, reels have double the reach of image posts on instagram. The maximum engagement rate for an account is found at 20 reels per week.",
    author: "– Later.com",
  },
  {
    quote:
      "Make some noise: Since TikTok is a sounds-on environment, adding audio to your video like a commercial sound,voiceover, or talking to the camera can significantly increase your impressions. 93% of top-performing videos use audio.",
    author: "– Tiktok",
  },
  {
    quote:
      "Almost 9 in 10 B2B buyers have watched video in the last 3 months for the purpose of learning about a product or service, and almost half (46%) have watched video at least 5 times in pursuit of product and service research.",
    author: "– MarketingCharts.com",
  },
  {
    quote:
      "Our research revealed that people's eyes cannot resist new, immersive and moving formats, with participants in our lab experiment gazing five times longer at video than static content.",
    author: "– Meta",
  },
  {
    quote: "Design your videos to be played with the sound on. 93% of TikTokers use the platform with sound on, and 88% said sound is “essential” on the platform.",
    author: "– Hootsuite.com",
  },
  {
    quote: "For reach rate, on average, the more Reels and video content you post per week, the higher your overall reach.",
    author: "– Later.com",
  },
  {
    quote:
      "Keep it short and sweet, but not too brief: 1 in 4 top-performing videos have a duration between 21 and 34 seconds and see an average of a 1.6% increase in impressions.",
    author: "– Tiktok",
  },
  {
    quote: "Videos, on average, received more than twice the amount of comments than photos on Instagram.",
    author: "– Buffer.com",
  },
  {
    quote: "Reels are the Instagram version of TikTok. After implementing the four hacks I share below, my Instagram reach went up 500% in just a few weeks.",
    author: "– ByronLazine.com",
  },
  {
    quote: "Instagram Reels are still being heavily promoted by Instagram across the entire app experience.",
    author: "– Later.com",
  },
  {
    quote: "Think vertically: Videos shot in a vertical format see a 40.1% increase in impressions than videos using a square or horizontal aspect ratio.",
    author: "– Tiktok",
  },
  {
    quote: "Videos, on average, received more than twice the amount of comments than photos on Instagram.",
    author: "– Buffer.com",
  },
  {
    quote:
      "In December 2021, Head of Instagram Adam Mosseri announced the social media platform’s plan to double down on video. He confirmed that the platform would consolidate Instagram video formats around short-form content to continue to grow Instagram Reels.",
    author: "– SocialExaminer.com",
  },
  {
    quote:
      "Go full screen: Videos that fill the entire screen see a 60.5% increase in impressions on Tiktok. Remove any black space and format your video to the 9:16 aspect ratio.",
    author: "– Tiktok",
  },
];

export const Loading: FC<{ loading_msg: string | undefined; showQuotes: boolean }> = ({ loading_msg, showQuotes }) => {
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(getRandomInt(0, quotes.length - 1));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentQuoteIndex((currentQuoteIndex + 1) % quotes.length);
    }, 9000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentQuoteIndex]);

  const currentQuote = quotes[currentQuoteIndex];

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: "Loading - Step 2",
      firebase_screen_class: "Loading",
    });
  }, []);

  return (
    <div className="loading-container">
      <div className="loading-inner">
        <div>
          <div className="warning">Please hold tight, we're experiencing high traffic, this could take a few minutes...</div>
          {loading_msg && <div className={`title ${!showQuotes && "small"}`}>{loading_msg}</div>}
        </div>
        <div className="lottie-container">
          <Lottie animationData={loading} loop={true} style={{ height: "25vh" }} />
        </div>
        {showQuotes && (
          <div className="quotes-container">
            <div className="quote-title">Did you know?</div>
            <div className="quote">"{currentQuote.quote}"</div>
            <div className="author">{currentQuote.author}</div>
          </div>
        )}
      </div>
    </div>
  );
};
