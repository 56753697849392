import type { FC } from "react";
import "../scss/Item-list.scss";

import { ImagesList } from "./images-list";
import { TextList } from "./text-list";
import { MusicList } from "./music-list";

export const ItemList: FC<{
  onChangeMode: (mode: string, type: string) => void;
  format: "horizontal" | "vertical";
  type: "images" | "stock" | "text" | "title" | "music";
  items: any[];
}> = ({ onChangeMode, type, format, items }) => {
  const typeList: any = {
    images: { height: "120" },
    stock: { height: "96" },
    text: { height: "92" },
    title: { height: "92" },
    music: { height: "70" },
  };

  function renderImageList(type: any, format: string) {
    return <ImagesList onChangeMode={onChangeMode} items={items} height={typeList[type].height} type={type} format={format} />;
  }

  function renderTextList(type: any, format: string) {
    return <TextList onChangeMode={onChangeMode} items={items} height={typeList[type].height} type={type} format={format} />;
  }

  function renderMusicList(type: any, format: string) {
    return <MusicList onChangeMode={onChangeMode} items={items} height={typeList[type].height} type={type} format={format} />;
  }

  return (
    <>
      {(type === "images" || type === "stock") && renderImageList(type, format)}
      {(type === "text" || type === "title") && renderTextList(type, format)}
      {type === "music" && renderMusicList(type, format)}
    </>
  );
};
