import { FC, ChangeEvent, useContext, useRef } from "react";

import { EditorContext } from "../App";
import "../scss/EditorToolbar.scss";

import { ArrowBackIos, MusicNote, MusicOff, Mic, MicOff, PlayArrow, IosShare } from "@mui/icons-material";
import { backgroundTracks } from "../constants/backgroundTracks";

import { getAnalytics, logEvent } from "firebase/analytics";
const analytics = getAnalytics();

export const EditorToolbar: FC<{
  generateVideoCallback: (event: any) => Promise<void>;
  handleShowShareModal: (variation: boolean, url?: string) => void;
}> = ({ generateVideoCallback, handleShowShareModal }) => {
  const editor_context = useContext(EditorContext);

  const musicRef = useRef<HTMLInputElement>(null);
  const ttsRef = useRef<HTMLInputElement>(null);

  const rangesRefs = { music_volume: musicRef, tts_volume: ttsRef };

  const onVolumeChange = (event: ChangeEvent, range: string) => {
    event.preventDefault();
    event.stopPropagation();
    const target = event.target as any;
    if (target !== undefined) {
      const min = target.min;
      const max = target.max;
      const val = target.valueAsNumber;
      target.style.backgroundSize = ((val - min) * 100) / (max - min) + "% 100%";
      let state = {} as any;
      state[range] = val;
      editor_context.updateEditorContext(state);
    }
  };

  const mute = (range: "tts_volume" | "music_volume") => {
    let state = {} as any;
    const targetRange = rangesRefs[range].current as HTMLInputElement;
    if (targetRange !== undefined) {
      if (editor_context[range] !== 0) {
        state[range] = 0;
        targetRange.valueAsNumber = 0;
        if (targetRange.style !== undefined) targetRange.style.backgroundSize = "0% 100%";
      } else {
        if (targetRange.style !== undefined) targetRange.style.backgroundSize = "50% 100%";
        state[range] = 50;
      }
      editor_context.updateEditorContext(state);
    }
  };

  const onBack = () => {
    editor_context.updateEditorContext({ selected: -1 });
  };

  return (
    <div className="toolbar">
      <div className="inner">
        <div className="back" onClick={onBack}>
          <ArrowBackIos sx={{ color: "rgb(232, 236, 241)" }} />
        </div>
        <div className="controls">
          <div className="inner-left">
            <div className="track-and-volume-container">
              <div className="track-name">
                music: "{backgroundTracks.find((track) => track.id === editor_context.propositions[editor_context.selected]?.video_data?.backgroundTrackId)?.title}"
              </div>
              <div className="volume">
                <div className="volume-icon" onClick={() => mute("music_volume")}>
                  {editor_context.music_volume !== 0 ? <MusicNote sx={{ color: "rgb(232, 236, 241)" }} /> : <MusicOff sx={{ color: "rgba(232, 236, 241, 0.3)" }} />}
                </div>
                <div className="range-container">
                  <input
                    ref={musicRef}
                    className="range form-range"
                    type="range"
                    value={editor_context.music_volume}
                    onChange={(e) => onVolumeChange(e, "music_volume")}
                    min="0"
                    max="100"
                    style={{ backgroundSize: "10% 100%" }}
                  />
                </div>
              </div>
            </div>
            <div className="volume">
              <div className="volume-icon" onClick={() => mute("tts_volume")}>
                {editor_context.tts_volume !== 0 ? <Mic sx={{ color: "rgb(232, 236, 241)" }} /> : <MicOff sx={{ color: "rgba(232, 236, 241, 0.3)" }} />}
              </div>
              <div className="range-container">
                <input
                  ref={ttsRef}
                  className="range form-range"
                  type="range"
                  value={editor_context.tts_volume}
                  onChange={(e) => onVolumeChange(e, "tts_volume")}
                  min="0"
                  max="100"
                  style={{ backgroundSize: "80% 100%" }}
                />
              </div>
            </div>
          </div>
          <div className="inner-right">
            <div className="preview" onClick={generateVideoCallback}>
              <PlayArrow sx={{ color: "rgb(232, 236, 241)", fontSize: "32px" }} />
              <span>Preview</span>
            </div>
            <div
              className="share"
              onClick={() => {
                handleShowShareModal(false);
                logEvent(analytics, "share_trigger", {
                  trigger_type: "share",
                  firebase_screen: "Editor - Step 4",
                  firebase_screen_class: "EditorToolbar",
                });
              }}
            >
              <IosShare sx={{ color: "rgb(232, 236, 241)" }} />
              <span>Share</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
