export const backgroundTracks = [
  {
    id: "1",
    style: "chill",
    title: "Chill 1",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/chill_1.mp3",
  },
  {
    id: "2",
    style: "chill",
    title: "Chill 2",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/chill_2.mp3",
  },
  {
    id: "3",
    style: "chill",
    title: "Chill 3",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/chill_3.mp3",
  },
  {
    id: "4",
    style: "chill",
    title: "Chill 4",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/chill_4.mp3",
  },
  {
    id: "5",
    style: "chill",
    title: "Chill 5",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/chill_5.mp3",
  },
  {
    id: "6",
    style: "chill",
    title: "Chill 6",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/chill_6.mp3",
  },
  {
    id: "7",
    style: "chill",
    title: "Chill 7",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/chill_7.mp3",
  },
  {
    id: "8",
    style: "chill",
    title: "Chill 8",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/chill_8.mp3",
  },
  {
    id: "9",
    style: "chill",
    title: "Chill 9",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/chill_9.mp3",
  },
  {
    id: "10",
    style: "chill",
    title: "Chill 10",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/chill_10.mp3",
  },
  {
    id: "11",
    style: "chill",
    title: "Chill 11",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/chill_11.mp3",
  },
  {
    id: "12",
    style: "upbeat",
    title: "Upbeat 1",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/upbeat_1.mp3",
  },
  {
    id: "13",
    style: "upbeat",
    title: "Upbeat 2",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/upbeat_2.mp3",
  },
  {
    id: "14",
    style: "upbeat",
    title: "Upbeat 3",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/upbeat_3.mp3",
  },
  {
    id: "15",
    style: "upbeat",
    title: "Upbeat 4",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/upbeat_4.mp3",
  },
  {
    id: "16",
    style: "upbeat",
    title: "Upbeat 5",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/upbeat_5.mp3",
  },
  {
    id: "17",
    style: "upbeat",
    title: "Upbeat 6",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/upbeat_6.mp3",
  },
  {
    id: "18",
    style: "upbeat",
    title: "Upbeat 7",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/upbeat_7.mp3",
  },
  {
    id: "19",
    style: "reflective",
    title: "Reflective 1",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/reflective_1.mp3",
  },
  {
    id: "20",
    style: "reflective",
    title: "Reflective 2",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/reflective_2.mp3",
  },
  {
    id: "21",
    style: "reflective",
    title: "Reflective 3",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/reflective_3.mp3",
  },
  {
    id: "22",
    style: "reflective",
    title: "Reflective 4",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/reflective_4.mp3",
  },
  {
    id: "23",
    style: "reflective",
    title: "Reflective 5",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/reflective_5.mp3",
  },
  {
    id: "24",
    style: "reflective",
    title: "Reflective 6",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/reflective_6.mp3",
  },
  {
    id: "25",
    style: "reflective",
    title: "Reflective 7",
    src: "https://s3.eu-central-1.amazonaws.com/static.dev.memtell/tests-maxime/musics/reflective_7.mp3",
  },
];
