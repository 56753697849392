import { FC, ChangeEvent, useState, useRef, useEffect } from "react";
import { PropositionFormConfiguration } from "../constants/Interfaces";
import "../scss/VideoForm.scss";
import { HeadlessSelect } from "./HeadlessSelect";
import { useOutsideClick } from "./useOutsideClick";

import { getAnalytics, logEvent } from "firebase/analytics";
const analytics = getAnalytics();

export interface VideoFormProps {
  handleChangeSelect: (value: string, state: string) => void;
  platform: string;
  based_on: string;
  uri: string;
  handleChangeUri: (event: ChangeEvent) => void;
  duration_seconds: string;
  tone: string;
  handleSubmitInitialForm: (event: any, configuration?: PropositionFormConfiguration, initial_loading_type?: string) => void;
  handleShowShareModal: (variation: boolean, url?: string) => void;
}

const platforms: string[] = ["Instagram", "Tiktok", "Snapchat", "Twitter", "Linkedin", "Youtube Shorts", "Several"];
const articleTypes: string[] = ["article", "guide", "how to", "top", "story"];
const durations: string[] = ["15s", "30s", "60s (pro)", "90s (pro)"];
const tones: string[] = ["fun", "professional", "factual"];

export const VideoForm: FC<VideoFormProps> = ({
  handleChangeSelect,
  platform,
  based_on,
  uri,
  handleChangeUri,
  duration_seconds,
  tone,
  handleSubmitInitialForm,
  handleShowShareModal,
}) => {
  const uriInput = useRef<HTMLInputElement>(null);

  const [hasErrors, setHasErrors] = useState(false);

  const checkForErrors = (event: any) => {
    let checkUrl;
    event.preventDefault();
    logEvent(analytics, "form_submit", {
      platform: platform,
      based_on: based_on,
      uri: uri,
      duration_seconds: duration_seconds,
      tone: tone,
    });
    logEvent(analytics, "tutorial_begin");
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      checkUrl = new URL(uri);
      setHasErrors(false);
      handleSubmitInitialForm(event);
    } catch (err) {
      console.log("invalid url");
      setHasErrors(true);
    }
  };

  const cleanErrors = (event: any) => {
    setHasErrors(false);
    handleChangeUri(event);
  };

  useOutsideClick(uriInput.current!, () => {
    setHasErrors(false);
  });

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: "Form - Step 1",
      firebase_screen_class: "VideoForm",
    });
  }, []);

  return (
    <div className="video-form-container">
      <form onSubmit={checkForErrors} className="video-form">
        <div className="form-line">
          <h1>I want a video for my</h1>
          <HeadlessSelect value={platform} onChange={handleChangeSelect} options={platforms} state="platform" />
          <h1>account</h1>
        </div>
        <div className="form-line">
          <h1>based on my</h1>
          <HeadlessSelect value={based_on} onChange={handleChangeSelect} options={articleTypes} state="based_on" />
          <h1>available here:</h1>
        </div>
        <div className={"form-line"}>
          <input ref={uriInput} className={hasErrors ? "error" : ""} id="url" type="text" value={uri} onChange={cleanErrors} placeholder="https://..." />
        </div>

        <div className="form-line">
          <h1>The video should be approx.</h1>
          <HeadlessSelect
            value={duration_seconds}
            onChange={handleChangeSelect}
            options={durations}
            state="duration_seconds"
            disabled={[2, 3]}
            handleDisabled={handleShowShareModal}
          />
          <h1> long,</h1>
        </div>
        <div className="form-line">
          <h1>and have a</h1>
          <HeadlessSelect value={tone} onChange={handleChangeSelect} options={tones} state="tone" />
          <h1>tone.</h1>
        </div>
        <div>
          <input type="submit" value="Create" className="submit" />
        </div>
      </form>
    </div>
  );
};
