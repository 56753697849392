import { type FC, useEffect } from "react";
import { useRef, useState, useContext } from "react";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../scss/VideoPreview.scss";
import { Edit, IosShare, PlayArrow, ThumbDown, Pause, Replay } from "@mui/icons-material";
import { EditorContext } from "../App";
import { Slide, VideoData } from "../constants/Interfaces";

import { getAnalytics, logEvent } from "firebase/analytics";
const analytics = getAnalytics();

export const VideoPreview: FC<{
  placeholder: boolean;
  videoData?: VideoData;
  slides?: Slide[];
  allow_variation_request: boolean;
  propositionId: number;
  onMoreVariation: (event: any) => void;
  onRetry: () => void;
  handleShowShareModal: (variation: boolean, url?: string) => void;
}> = ({ placeholder, videoData, slides, allow_variation_request, onMoreVariation, onRetry, handleShowShareModal, propositionId }) => {
  const editor_context = useContext(EditorContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [brigthness, setBrigthness] = useState(true);
  const [loadingError, setLoadingError] = useState(false);

  const videoref = useRef<HTMLVideoElement>(null);

  const onEdit = (propositionId: number) => {
    editor_context.updateEditorContext({ selected: propositionId });
  };

  useEffect(() => {
    setLoadingError(false);
  }, [placeholder]);

  function renderLoadingError() {
    return (
      <>
        <div className="video-container">
          <div className="top">
            <div className="video">
              <div className="frame1">
                <span
                  className="col-12"
                  style={{ height: "100%", borderRadius: "2px", borderColor: "rgb(232, 236, 241)", borderStyle: "dashed", textAlign: "center", padding: "1rem" }}
                >
                  An error happend while generating your video, please try again:
                </span>
                <Replay
                  sx={{
                    color: "rgb(232, 236, 241)",
                    fontSize: "40px",
                    position: "absolute",
                    zIndex: 10,
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onRetry();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="bottom"></div>
        </div>
      </>
    );
  }

  function renderPlaceholder() {
    return (
      <>
        <div className="video-container">
          <div className="top">
            <div className="video">
              <div className="frame1 placeholder-glow">
                <span className="placeholder col-12" style={{ height: "100%", borderRadius: "2px" }}></span>
              </div>
            </div>
            <div className="feedback" style={{ width: "30px" }}>
              <div className="placeholder-glow col-12" style={{ height: "30px", borderRadius: "2px" }}>
                <span className="placeholder col-12" style={{ height: "24px", borderRadius: "2px" }}></span>
              </div>
              <div className="placeholder-glow col-12" style={{ height: "30px", borderRadius: "2px" }}>
                <span className="placeholder col-12" style={{ height: "24px", borderRadius: "2px" }}></span>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="placeholder-glow col-4">
              <span className="placeholder col-12" style={{ height: "32px", borderRadius: "8px" }}></span>
            </div>
            <div className="placeholder-glow col-4">
              <span className="placeholder col-12" style={{ height: "32px", borderRadius: "8px" }}></span>
            </div>
          </div>
        </div>
      </>
    );
  }

  function render() {
    return (
      <>
        <div className="video-container">
          <div className="top">
            <div className="video">
              {!isPlaying && (
                <PlayArrow
                  sx={{
                    color: "rgb(232, 236, 241)",
                    fontSize: "40px",
                    position: "absolute",
                    zIndex: 10,
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (videoref.current && videoref.current.paused) {
                      videoref.current.play();
                      setIsPlaying(true);
                      setBrigthness(false);
                    }
                  }}
                />
              )}
              {isPlaying && (
                <Pause
                  className="PauseIcon"
                  sx={{
                    color: "rgb(232, 236, 241)",
                    fontSize: "40px",
                    position: "absolute",
                    zIndex: 10,
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                    display: "none",
                  }}
                  onClick={() => {
                    if (videoref.current && !videoref.current.paused) {
                      videoref.current.pause();
                      setIsPlaying(false);
                      setBrigthness(true);
                    }
                  }}
                />
              )}
              {/* add class frame1 brigthness 1 if isPlaying */}
              <div className={`frame1  ${brigthness ? "brigthness" : ""}`}>
                <video
                  ref={videoref}
                  style={{
                    height: "100%",
                  }}
                  controls={false}
                  onEnded={() => {
                    setIsPlaying(false);
                    setBrigthness(true);
                    if (videoref.current) {
                      videoref.current.currentTime = 0;
                    }
                  }}
                  onError={() => {
                    setLoadingError(true);
                  }}
                >
                  <source src={videoData?.src}></source>
                </video>
              </div>
            </div>
            <div className="feedback">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                onClick={() => {
                  handleShowShareModal(true);
                  logEvent(analytics, "share_trigger", {
                    trigger_type: "more_variations",
                    firebase_screen: "Results - Step 3",
                    firebase_screen_class: "VideoPreview",
                  });
                }}
              >
                <path
                  d="M 18.25 17 L 18.25 13.75 L 15 13.75 L 15 12.25 L 18.25 12.25 L 18.25 9 L 19.75 9 L 19.75 12.25 L 23 12.25 L 23 13.75 L 19.75 13.75 L 19.75 17 Z M 11 21 L 7.825 18.15 C 6.625 17.067 5.596 16.1 4.737 15.25 C 3.879 14.4 3.171 13.6 2.612 12.85 C 2.054 12.1 1.646 11.375 1.388 10.675 C 1.129 9.975 1 9.242 1 8.475 C 1 6.908 1.525 5.604 2.575 4.562 C 3.625 3.521 4.933 3 6.5 3 C 7.367 3 8.192 3.179 8.975 3.538 C 9.758 3.896 10.433 4.408 11 5.075 C 11.567 4.408 12.242 3.896 13.025 3.538 C 13.808 3.179 14.633 3 15.5 3 C 16.917 3 18.104 3.429 19.062 4.288 C 20.021 5.146 20.617 6.15 20.85 7.3 C 20.55 7.183 20.25 7.096 19.95 7.038 C 19.65 6.979 19.358 6.95 19.075 6.95 C 17.392 6.95 15.958 7.538 14.775 8.712 C 13.592 9.888 13 11.317 13 13 C 13 13.867 13.175 14.687 13.525 15.462 C 13.875 16.238 14.367 16.9 15 17.45 C 14.683 17.733 14.271 18.096 13.763 18.538 C 13.254 18.979 12.817 19.367 12.45 19.7 Z"
                  fill="rgb(232, 236, 241)"
                ></path>
              </svg>
              <ThumbDown
                sx={{ color: "rgb(232, 236, 241)", fontSize: "20px" }}
                onClick={() => {
                  handleShowShareModal(true);
                  logEvent(analytics, "share_trigger", {
                    trigger_type: "more_variations",
                    firebase_screen: "Results - Step 3",
                    firebase_screen_class: "VideoPreview",
                  });
                }}
              />
            </div>
          </div>
          <div className="bottom">
            <div className="edit" onClick={() => onEdit(propositionId)}>
              <Edit sx={{ color: "rgb(232, 236, 241)", fontSize: "20px" }} />
              <span>Edit</span>
            </div>
            <div
              className="share-video"
              onClick={() => {
                handleShowShareModal(false, editor_context.propositions[propositionId]?.video_data.src);
                logEvent(analytics, "share-trigger", {
                  trigger_type: "share",
                  firebase_screen: "Results - Step 3",
                  firebase_screen_class: "VideoPreview",
                });
              }}
            >
              <IosShare sx={{ color: "rgb(232, 236, 241)", fontSize: "20px" }} />
              <span>Share</span>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (loadingError) {
    return renderLoadingError();
  }
  return placeholder ? renderPlaceholder() : render();
};
