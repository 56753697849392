import { FC, useEffect } from "react";

import "../scss/Editor.scss";

import { EditorRight } from "./EditorRight";
import { EditorLeft } from "./EditorLeft";
import { EditorToolbar } from "./EditorToolbar";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ContainerCards } from "./ContainerCards";

import { getAnalytics, logEvent } from "firebase/analytics";
const analytics = getAnalytics();

export const Editor: FC<{
  titles: string[];
  alternate_texts: string[];
  images: { src: string; height: number; width: number }[];
  generateVideoCallback: (event: any) => Promise<void>;
  handleShowShareModal: (variation: boolean, url?: string) => void;
}> = ({ titles, alternate_texts, images, generateVideoCallback, handleShowShareModal }) => {
  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: "Editor - Step 4",
      firebase_screen_class: "Editor",
    });
  }, []);

  return (
    <div className="d-flex flex-grow-1">
      <div className="editor">
        <EditorToolbar generateVideoCallback={generateVideoCallback} handleShowShareModal={handleShowShareModal} />
        <div className="sides">
          <div className="left">
            <EditorLeft />
          </div>
          <div className="right">
            <EditorRight titles={titles} alternate_texts={alternate_texts} images={images} />
          </div>
        </div>
        <div className="timeline">
          <DndProvider backend={HTML5Backend}>
            <ContainerCards />
          </DndProvider>
        </div>
      </div>
    </div>
  );
};
