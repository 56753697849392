import React from "react";
import "./AppRouter.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import { Login } from "./pages/Login";

class AppRouter extends React.Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<App />}></Route>
            <Route path="/login" element={<Login />}></Route>
          </Routes>
        </div>
      </Router>
    );
  }
}
export default AppRouter;
