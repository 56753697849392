import { FC, ChangeEvent, useRef, useContext, useEffect } from "react";
import "../scss/UploadImages.scss";
import { EditorContext } from "../App";
import storage from "../utilities/firebaseConfig.js";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

import { Upload } from "@mui/icons-material";

export const UploadImages: FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const editor_context = useContext(EditorContext);

  function firebaseUpload(file: File, uuid: string) {
    if (!file) {
      return alert("Please choose a file first!");
    }
    try {
      const storageRef = ref(storage, `${file.name}`);
      try {
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            // console.log(percent);
          },
          (err) => console.log(err),
          () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              if (url) {
                const image_to_update = editor_context.images_assets.find((image) => image.id === uuid);
                if (image_to_update != null) {
                  image_to_update.src = url;
                }
                editor_context.updateEditorContext({ images_assets: editor_context.images_assets });
              } else {
                throw new Error("no url returned by Firebase");
              }
            });
          }
        );
      } catch (e) {
        return console.log("Error while uploading file", e);
      }
    } catch (e) {
      return console.log("Error while uploading file", e);
    }
  }

  async function onImageChange(e: ChangeEvent<HTMLInputElement>) {
    const maxFileSize = 5242880; // 5 MB
    if (e.target.files !== null) {
      // console.log(e.target.files);
      const newFiles = [] as any;
      for (const file of Array.from(e.target.files)) {
        if (file.size < maxFileSize) {
          let img = new Image();
          img.src = URL.createObjectURL(file).toString();
          const uuid = uuidv4();
          try {
            await img.decode();
            try {
              firebaseUpload(file, uuid);
            } catch (e) {
              console.log("firebase upload error, ", e);
            }
            newFiles.push({ src: img.src, height: img.height, width: img.width, id: uuid });
          } catch {
            return alert("There was an error with your file.");
          }
        } else {
          return alert("Your file is too large (max 5MB)");
        }
      }
      return editor_context.updateEditorContext({ images_assets: editor_context.images_assets.splice(0, 0, ...newFiles) });
    }
  }

  function uploadFileTrigger() {
    if (inputRef.current !== null) {
      inputRef.current.click();
    }
  }

  useEffect(() => {}, [editor_context]);

  return (
    <>
      <div className="button-container">
        <div className="button" onClick={uploadFileTrigger}>
          <input ref={inputRef} className="input" type="file" multiple accept="image/*" onChange={onImageChange} />
          <Upload sx={{ color: "rgb(232, 236, 241)", fontSize: "18px" }} />
          <div>Upload Image</div>
        </div>
      </div>
    </>
  );
};
