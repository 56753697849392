import { FC } from "react";
import { useRef, useContext } from "react";
import { EditorContext } from "../App";

import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

export interface MusicProps {
  items: any;
  height: number;
  type: string;
  onChangeMode: (mode: string, type: string) => void;
  format: string;
}

export const MusicList: FC<MusicProps> = ({ onChangeMode, items, height, type, format }) => {
  const editor_context = useContext(EditorContext);
  const proposition = editor_context.propositions[editor_context.selected];
  const scrollElement = useRef<HTMLDivElement>(null);

  function sectionTitle() {
    let title = "";
    if (type === "music") title = "Musics";
    return title;
  }

  function scrollHorizontal(position: string) {
    if (scrollElement?.current) {
      const before = scrollElement.current.scrollLeft;
      let offsetWidth = 0;
      if (position === "left") {
        offsetWidth -= scrollElement.current.offsetWidth / 2;
      } else {
        offsetWidth += scrollElement.current.offsetWidth / 2;
      }
      scrollElement.current.scrollLeft += offsetWidth;
      if (position === "right" && before === scrollElement.current.scrollLeft) {
        onChangeMode("see_all", type);
      }
    }
  }

  const onClickItem = (item: { id: string; title: string; style: string; src: string }) => {
    if (proposition?.video_data) {
      const video_data = { ...proposition.video_data };
      video_data.backgroundTrackId = item.id;
      editor_context.updateEditorContext({ video_data: video_data }, editor_context.selected);
    }
  };

  function renderHorizontal() {
    const list = items.map((item: any, index: any) => {
      return (
        <div
          className="item music"
          key={index}
          onClick={() => {
            onClickItem(item);
          }}
        >
          <div className="music-top">
            <span className="music-title">{item.title}</span>
            <span className="select">select</span>
          </div>
          <audio controls>
            <source src={item.src} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      );
    });

    return (
      <>
        <div className="title">
          <div className="categorie">{sectionTitle()}</div>
          <div
            className="seeall"
            onClick={() => {
              onChangeMode("see_all", type);
            }}
          >
            see all
          </div>
        </div>
        <div className="container-horizontal" ref={scrollElement} style={{ height: height + "px" }}>
          <div
            onClick={() => {
              scrollHorizontal("left");
            }}
            className="arrow arrow-left"
            style={{ height: height + "px" }}
          >
            <span>
              <ArrowBackIos sx={{ color: "rgb(232, 236, 241)", fontSize: "28px" }} />
            </span>
          </div>
          {list}
          <div
            onClick={() => {
              scrollHorizontal("right");
            }}
            className="arrow arrow-right"
            style={{ height: height + "px" }}
          >
            <span>
              <ArrowForwardIos sx={{ color: "rgb(232, 236, 241)", fontSize: "28px" }} />
            </span>
          </div>
        </div>
      </>
    );
  }

  function renderVertical() {
    const list = items.map((item: any, index: any) => {
      return (
        <div
          className="item-music"
          key={index}
          onClick={() => {
            onClickItem(item);
          }}
        >
          <div className="music-top">
            <span className="music-title">{item.title}</span>
            <span className="select">select</span>
          </div>
          <audio controls>
            <source src={item.src} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      );
    });

    return (
      <>
        <div className="music-list-vertical">{list}</div>
      </>
    );
  }

  return <>{format === "horizontal" ? renderHorizontal() : renderVertical()}</>;
};
