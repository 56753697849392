import type { FC } from "react";
import { CropperRef, FixedCropper, ImageRestriction } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";

export const FakeCrop: FC<{ image: string; onCrop: (crop: any) => void, initialLeft?: number }> = ({ image, onCrop, initialLeft }) => {
  // image = "https://images.ctfassets.net/hrltx12pl8hq/3j5RylRv1ZdswxcBaMi0y7/b84fa97296bd2350db6ea194c0dce7db/Music_Icon.jpg";
  const onChange = (cropper: CropperRef) => {
    onCrop(cropper.getCoordinates());
  };

  return (
    <div style={{ width: "100%", height: "640px" }}>
      <FixedCropper
        src={image}
        onChange={onChange}
        stencilSize={{
          width: 360,
          height: 640,
        }}
        minHeight={650} //yeah I know it's not 640px but wtf works without bounding
        maxHeight={650}
        backgroundWrapperProps={{
          scaleImage: false,
        }}
        stencilProps={{
          handlers: false,
          lines: false,
          movable: false,
          resizable: false,
          transitions: false,
        }}
        imageRestriction={ImageRestriction.stencil}
        crossOrigin={false}
      />
    </div>
  );
};
