import { FC, useState, useRef } from "react";
import "../scss/HeadlessSelect.scss";

import { useOutsideClick } from "./useOutsideClick";
import { ArrowDropDown } from "@mui/icons-material";

import { getAnalytics, logEvent } from "firebase/analytics";
const analytics = getAnalytics();

export interface HeadlessSelectProps {
  options: any[];
  value: any;
  onChange: (value: string, state: string) => void;
  state: string;
  disabled?: number[];
  handleDisabled?: (e: any) => void;
}

export const HeadlessSelect: FC<HeadlessSelectProps> = ({ options, value, onChange, state, disabled, handleDisabled }) => {
  const selectInput = useRef<HTMLInputElement>(null);
  const selectContainer = useRef<HTMLInputElement>(null);

  const [open, setOpen] = useState(false);

  useOutsideClick(selectContainer.current!, () => {
    setOpen(false);
  });

  return (
    <div ref={selectContainer} className="select-container">
      <div ref={selectInput} className="select" onClick={() => setOpen(!open)}>
        <h1>{value}</h1>
        <ArrowDropDown sx={{ color: "#E8ECF1", fontSize: "36px", marginBottom: "8px" }} />
      </div>
      <div className={`drop-down ${open ? "open" : ""}`} style={{ top: `${selectInput?.current?.offsetTop ? selectInput?.current?.offsetTop + 72 : 0}px` }}>
        {options.map((option, i) => (
          <div
            key={i}
            className={`option ${option === value ? "selected" : ""} ${disabled && disabled?.find((el) => el === i) ? "disabled" : ""}`}
            onClick={() => {
              if (disabled?.find((el) => el === i) && handleDisabled !== undefined) {
                handleDisabled(true);
                logEvent(analytics, "share_trigger", {
                  trigger_type: "longer_variations",
                  firebase_screen: "Form - Step 1",
                  firebase_screen_class: "HeadlessSelect",
                });
              } else {
                onChange(option, state);
                setOpen(false);
              }
            }}
          >
            <h1>{option}</h1>
          </div>
        ))}
      </div>
    </div>
  );
};
