import update from "immutability-helper";
import type { FC } from "react";
import { useCallback, useState, useContext } from "react";
import { EditorContext } from "../App";
import { Card } from "./Card";

export interface Item {
  id: number;
  text: string;
  img: string;
}

export interface ContainerState {
  cards: Item[];
}

export const ContainerCards: FC = () => {
  const editor_context = useContext(EditorContext);
  const [cards, setCards] = useState((editor_context?.propositions[editor_context.selected]?.slides || []) as any[]);
  const insertCard = useCallback(
    (index: number) => {
      cards.splice(index, 0, {
        summary: "",
        image: "",
        tags: "",
        id: `${new Date().getTime()}`,
      });
      setCards(cards);
      editor_context.updateEditorContext({ slides: cards, currentSlide: index }, editor_context.selected);
    },
    [cards, editor_context]
  );

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setCards((prevCards: Item[]) => {
        const ret = update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as Item],
          ],
        });
        editor_context.updateEditorContext({ slides: ret }, editor_context.selected);
        return ret;
      });
    },
    [editor_context]
  );

  const renderCard = useCallback(
    (card: { id: string; summary: string; image: string }, index: number, isLast?: boolean) => {
      return <Card key={`${card.id ? card.id : index}`} index={index} slideRef={card} moveCard={moveCard} onInsert={insertCard} isLast={isLast} />;
    },
    [moveCard, insertCard]
  );

  return <>{cards.map((card, i) => renderCard(card, i, i === cards.length - 1))}</>;
};
