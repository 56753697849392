import { type FC, useContext } from "react";
import { useRef } from "react";
import { EditorContext } from "../App";
import { UploadImages } from "./UploadImages";

import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

export interface ImagesProps {
  items: any;
  height: number;
  type: string;
  onChangeMode: (mode: string, type: string) => void;
  format: string;
}

export const ImagesList: FC<ImagesProps> = ({ onChangeMode, items, height, type, format }) => {
  const editor_context = useContext(EditorContext);
  const proposition = editor_context.propositions[editor_context.selected];
  const scrollElement = useRef<HTMLDivElement>(null);

  function sectionTitle() {
    let title = "";
    if (type === "images") title = "Images from your pages";
    else if (type === "stock") title = "Stock images";
    return title;
  }

  function scrollHorizontal(position: string) {
    if (scrollElement?.current) {
      const before = scrollElement.current.scrollLeft;
      let offsetWidth = 0;
      if (position === "left") {
        offsetWidth -= scrollElement.current.offsetWidth;
      } else {
        offsetWidth += scrollElement.current.offsetWidth;
      }
      scrollElement.current.scrollLeft += offsetWidth;
      if (position === "right" && before === scrollElement.current.scrollLeft) {
        onChangeMode("see_all", type);
      }
    }
  }

  const onClickItem = (item: { src: string; height: number; width: number }) => {
    const slide = proposition?.slides && proposition?.slides.length > proposition?.currentSlide ? proposition.slides[proposition.currentSlide] : undefined;
    if (slide) {
      slide.image = item.src;
      slide.width = item.width;
      slide.height = item.height;
      if (slide.crop) {
        slide.crop = undefined;
      }
      editor_context.updateEditorContext({ slides: proposition?.slides }, editor_context.selected);
    }
  };

  function renderHorizontal() {
    const list = items.map((item: any, index: any) => {
      return (
        <div className="item" key={index}>
          <img
            src={item.src}
            alt=""
            onClick={() => {
              onClickItem(item);
            }}
          />
        </div>
      );
    });

    return (
      <>
        <div className="title">
          <div className="categorie">{sectionTitle()}</div>
          <div
            className="seeall"
            onClick={() => {
              onChangeMode("see_all", type);
            }}
          >
            see all
          </div>
        </div>
        <div className="container-horizontal" ref={scrollElement} style={{ height: height + "px" }}>
          <div
            onClick={() => {
              scrollHorizontal("left");
            }}
            className="arrow arrow-left"
            style={{ height: height + "px" }}
          >
            <span>
              <ArrowBackIos sx={{ color: "rgb(232, 236, 241)", fontSize: "28px" }} />
            </span>
          </div>
          {list}
          <div
            onClick={() => {
              scrollHorizontal("right");
            }}
            className="arrow arrow-right"
            style={{ height: height + "px" }}
          >
            <span>
              <ArrowForwardIos sx={{ color: "rgb(232, 236, 241)", fontSize: "28px" }} />
            </span>
          </div>
        </div>
        {type === "images" && <UploadImages />}
      </>
    );
  }

  function renderVertical() {
    const list = items.map((item: any, index: any) => {
      return (
        <img
          src={item.src}
          alt=""
          key={index}
          onClick={() => {
            onClickItem(item);
          }}
        />
      );
    });
    return (
      <>
        <div className="image-list-vertical">{list}</div>
      </>
    );
  }

  return <>{format === "horizontal" ? renderHorizontal() : renderVertical()}</>;
};
