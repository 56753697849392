import { useState, useContext, ChangeEvent, useEffect, useRef } from "react";
import type { FC } from "react";
import { EditorContext } from "../App";

import "../scss/WorkingSlide.scss";
import { ToolBarMode } from "./EditorLeft";
import { noBackgroundColor } from "../constants";
export interface WorkingSlideProps {
  updateToolBarMode: (mode: string) => void;
}

function getFontSize(textLength: number) {
  if (textLength <= 29) {
    return "font-lg";
  } else if (textLength <= 69) {
    return "font-md";
  }
  return "font-sm";
}

export const WorkingSlide: FC<WorkingSlideProps> = ({ updateToolBarMode }) => {
  // const textRef = useRef<HTMLSpanElement>(null);
  const editor_context = useContext(EditorContext);
  const proposition = editor_context.propositions[editor_context.selected];
  const [slide, setSlide] = useState(
    proposition?.slides && proposition?.slides.length > (proposition?.currentSlide || 0) ? proposition?.slides[proposition?.currentSlide] : undefined
  );
  const [summary, setSummary] = useState(slide?.summary);
  const [mode, setMode] = useState("preview");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const onTextBoxClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setMode("edit");
    updateToolBarMode(ToolBarMode.Text);
  };

  const onTextAreaBlur = () => {
    setMode("preview");
    editor_context.updateEditorContext({ slides: proposition?.slides }, editor_context.selected);
  };
  const onTextAreaFocus = (event: any) => {
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };

  const onTextAreaChange = (event: ChangeEvent) => {
    event.preventDefault();
    if (slide && textAreaRef.current) {
      slide.summary = textAreaRef.current.value;
      setSummary(slide.summary);
    }
  };

  const bubbleAdditionalStyle: React.CSSProperties = { cursor: "text" };
  if (slide && slide.summary.length === 0) {
    bubbleAdditionalStyle.height = "2rem";
  }

  const resizeTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    }
  };

  useEffect(resizeTextArea, [summary]);

  useEffect(() => {
    const selectedSlide =
      proposition?.slides && proposition?.currentSlide !== undefined && proposition.slides.length > (proposition?.currentSlide || 0)
        ? proposition?.slides[proposition?.currentSlide]
        : undefined;
    if (!Object.is(selectedSlide, slide)) {
      setMode("preview");
      setSlide(selectedSlide);
    }
  }, [editor_context, proposition?.currentSlide, proposition?.slides, slide]);

  const textAreaVisibility: React.CSSProperties = mode === "preview" ? { display: "none" } : {};

  const imageAdditionalStyle: React.CSSProperties = {
    position: "relative",
    backgroundSize: slide?.fit || "cover",
    backgroundColor: "black",
  } as React.CSSProperties;
  if (slide && [undefined, null, ""].indexOf(slide?.image) === -1) {
    imageAdditionalStyle.backgroundImage = "url(" + slide.image + ")";
    if (slide.crop && slide.fit !== "contain") {
      let offset = 0;
      if (slide.crop?.width && slide?.width) offset = (-274 * slide.crop.left) / slide.crop.width;
      imageAdditionalStyle.backgroundPositionX = `${offset}px`;
    }
  } else {
    imageAdditionalStyle.backgroundColor = noBackgroundColor;
  }
  return (
    <>
      {slide && (
        <div
          className="working-slide"
          style={imageAdditionalStyle}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            updateToolBarMode(ToolBarMode.Background);
          }}
        >
          {mode === "preview" && (
            <div
              className="textbox"
              style={{
                position: "absolute",
                top: slide.alignment || "50%",
                transform: "translateY(-50%)",
                ...bubbleAdditionalStyle,
              }}
              onClick={onTextBoxClick}
            >
              <span className={getFontSize(slide.summary.length)}>{slide.summary}</span>
            </div>
          )}
          {mode === "edit" && (
            <textarea
              ref={textAreaRef}
              value={slide.summary}
              className={"textbox " + getFontSize(slide.summary.length)}
              style={{
                position: "absolute",
                top: slide.alignment || "50%",
                transform: "translateY(-50%)",
                ...bubbleAdditionalStyle,
                ...textAreaVisibility,
              }}
              onBlur={onTextAreaBlur}
              onChange={onTextAreaChange}
              onFocus={onTextAreaFocus}
              autoFocus
            ></textarea>
          )}
          <svg style={{ visibility: "hidden", position: "absolute" }} width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
              <filter id="goo">
                <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur" />
                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                <feComposite in="SourceGraphic" in2="goo" operator="atop" />
              </filter>
            </defs>
          </svg>
        </div>
      )}
    </>
  );
};
