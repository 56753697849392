import type { Identifier, XYCoord } from "dnd-core";
import { FC, useEffect } from "react";
import { useRef, useContext, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { EditorContext } from "../App";

import { Close } from "@mui/icons-material";

import "../scss/Card.scss";
import { noBackgroundColor } from "../constants";

export const ItemTypes = {
  CARD: "card",
};

const selected = {
  border: "4px solid #0071EB",
  borderRadius: "9px",
} as React.CSSProperties;

export interface CardProps {
  slideRef: {
    id: string;
    summary: string;
    image: string;
  };
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onInsert?: (index: number) => void;
  isLast?: boolean;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const Card: FC<CardProps> = ({ slideRef, index, moveCard, onInsert, isLast }) => {
  const ref = useRef<HTMLDivElement>(null);
  const editor_context = useContext(EditorContext);
  const [IsSelected, setIsSelected] = useState(editor_context?.propositions[editor_context.selected] ? editor_context?.propositions[editor_context.selected]?.currentSlide : 0);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleX = (hoverBoundingRect.left - hoverBoundingRect.right) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.right;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { slideRef, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  function handleClick() {
    // console.log("Card click");
    editor_context.updateEditorContext({ currentSlide: index }, editor_context.selected);
  }

  useEffect(() => {
    setIsSelected(editor_context?.propositions[editor_context.selected]?.currentSlide);
  }, [editor_context]);

  const selectedStyles = IsSelected === index ? selected : {};

  const [isHoverDelete, setIsHoverDelete] = useState(false);

  const handleMouseEnterDeleteBox = () => {
    setIsHoverDelete(true);
  };
  const handleMouseLeaveDeleteBox = () => {
    setIsHoverDelete(false);
  };

  function handleDeleteClick(event: any) {
    event.preventDefault();
    event.stopPropagation();
    const slides = editor_context?.propositions[editor_context.selected]?.slides;
    if ((slides || []).length > 1) {
      slides?.splice(index, 1);
      editor_context.updateEditorContext({ slides: slides }, editor_context.selected);
    }
  }

  const styleDeleteBox: React.CSSProperties = {
    display: isHoverDelete ? "block" : "none",
  };

  // useEffect(() => {
  //     console.log("isHoverDelete", isHoverDelete)
  //     console.log("isDragging", isDragging)
  //     console.log("isHoverDelete && !isDragging", isHoverDelete && !isDragging,"\n\n")
  // }, [isHoverDelete, isDragging])

  const styleCursorCard: React.CSSProperties = {
    cursor: "move",
  };
  const styleBackgroundCard: React.CSSProperties = {
    backgroundColor: noBackgroundColor,
  };
  if ([null, undefined, ""].indexOf(slideRef.image) === -1) {
    styleBackgroundCard.backgroundImage = "url(" + slideRef.image + ")";
  }

  return (
    <>
      {/* comment line bellow if no + sign needed before first card */}
      {/* {onInsert && index === 0 && <span style={insertIconStyle} onClick={() => onInsert(index)}>+</span>} */}
      {/* <div ref={ref} className="mini-card" style={{ opacity, ...selectedStyles, ...styleCursorCard }} data-handler-id={handlerId} onClick={handleClick} onMouseEnter={handleMouseEnterDeleteBox} onMouseLeave={handleMouseLeaveDeleteBox}>
                <div className="delete" onClick={handleDeleteClick}>
                    <Close sx={{ color: 'rgb(232, 236, 241)' }}/>
                </div>
                <img src={slideRef.image}></img>
            </div> */}
      <div
        ref={ref}
        className="mini-card2"
        style={{ opacity, ...selectedStyles, ...styleCursorCard, ...styleBackgroundCard }}
        data-handler-id={handlerId}
        onClick={handleClick}
        onMouseEnter={handleMouseEnterDeleteBox}
        onMouseLeave={handleMouseLeaveDeleteBox}
      >
        <div className="round" style={{ ...styleDeleteBox }} onClick={handleDeleteClick}>
          <Close className="delete" sx={{ color: "rgb(232, 236, 241)", fontSize: 14 }} />
        </div>
      </div>
      {onInsert && (
        <span className="add-card" onClick={() => onInsert(index + 1)}>
          +
        </span>
      )}
      {/* use line bellow instead of line above if no + sign needed after last card */}
      {/* {onInsert && !isLast && <span style={insertIconStyle} onClick={() => onInsert(index+1)}>+</span>} */}
    </>
  );
};
