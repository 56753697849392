import { FC, useEffect } from "react";
import { useCallback } from "react";

import "../scss/Results.scss";
import { VideoPreview } from "./VideoPreview";
import { Proposition, PropositionFormConfiguration } from "../constants/Interfaces";

import { getAnalytics, logEvent } from "firebase/analytics";
const analytics = getAnalytics();

export const Results: FC<{
  propositions: (Proposition | undefined)[];
  article: string;
  channel: string;
  type: string;
  duration_seconds: string;
  tone: string;
  onMoreVariation: (event: any, configuration?: PropositionFormConfiguration, initial_loading_type?: string) => void;
  allow_variation_request: boolean;
  handleShowShareModal: (variation: boolean, url?: string) => void;
}> = ({ propositions, article, channel, type, duration_seconds, tone, onMoreVariation, allow_variation_request, handleShowShareModal }) => {
  const handleMoreVariation = useCallback(
    (event: any) => {
      onMoreVariation(
        event,
        {
          start_index: propositions.length,
          proposition_amount: 3,
          individual_specifications: [{}, { random_pexel: true }, { alternate_summary: true }],
          do_scrap: false,
        },
        "internal"
      );
    },
    [onMoreVariation, propositions.length]
  );

  const handleRetry = useCallback(
    (index: number, individual_specifications: any) => {
      onMoreVariation(
        undefined,
        {
          start_index: index,
          proposition_amount: 1,
          individual_specifications: [individual_specifications],
          do_scrap: false,
          retry: true,
        },
        "internal"
      );
    },
    [onMoreVariation]
  );

  const renderPreviews = useCallback(() => {
    const videoPreviews = propositions.map((proposition: Proposition | undefined, index: number) => {
      return (
        <VideoPreview
          key={index}
          propositionId={index}
          placeholder={proposition ? false : true}
          videoData={proposition?.video_data}
          slides={proposition?.slides}
          allow_variation_request={allow_variation_request}
          onMoreVariation={handleMoreVariation}
          onRetry={handleRetry.bind(undefined, index, proposition?.individual_specifications)}
          handleShowShareModal={handleShowShareModal}
        />
      );
    });
    return videoPreviews;
  }, [propositions, allow_variation_request, handleMoreVariation, handleRetry, handleShowShareModal]);

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: "Results - Step 3",
      firebase_screen_class: "Results",
    });
  }, []);

  return (
    <div className="results">
      <div className="info">
        <div className="article">
          <span className="title-info">Article: </span>
          <span className="url">{article}</span>
        </div>
        <div className="tone">
          <div className="wrap">
            <span className="title-info">Channel: </span>
            <span className="tone-info">{channel}</span>
          </div>

          <div className="wrap">
            <span className="title-info">Type: </span>
            <span className="tone-info">{type}</span>
          </div>

          <div className="wrap">
            <span className="title-info">Duration: </span>
            <span className="tone-info">{duration_seconds} seconds</span>
          </div>

          <div className="wrap">
            <span className="title-info">Tone: </span>
            <span className="tone-info">{tone}</span>
          </div>
        </div>
      </div>
      <div className="content">
        <h4 className="title-content">Choose one of the videos below, edit the slides first (texts, images etc.) or share it right away:</h4>
        <div className="video-results">{renderPreviews()}</div>
      </div>
      {allow_variation_request && (
        <div className="variation">
          <div
            className="variation-btn"
            onClick={() => {
              handleShowShareModal(true);
              logEvent(analytics, "share_trigger", {
                trigger_type: "more_variations",
                firebase_screen: "Results - Step 3",
                firebase_screen_class: "Results",
              });
            }}
          >
            <span>More variations</span>
          </div>
        </div>
      )}
    </div>
  );
};
