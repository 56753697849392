import { FC, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Loading } from "./Loading";
import "../scss/PreviewModal.scss";

import { IosShare } from "@mui/icons-material";

import { getAnalytics, logEvent } from "firebase/analytics";
const analytics = getAnalytics();

export interface PreviewModalProps {
  handleClosePreviewModal: () => void;
  showPreviewModal: boolean;
  videoData:
    | {
        width: string;
        height: string;
        src: string;
      }
    | undefined;
  loadingPreviewModal: boolean;
  handleShowShareModal: (variation: boolean, url?: string) => void;
}

export const PreviewModal: FC<PreviewModalProps> = ({ handleClosePreviewModal, showPreviewModal, videoData, loadingPreviewModal, handleShowShareModal }) => {
  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: "Preview Modal - Step 5",
      firebase_screen_class: "PreviewModal",
    });
  }, [showPreviewModal]);

  return (
    <Modal show={showPreviewModal} onHide={handleClosePreviewModal}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="video-container">
          {loadingPreviewModal && <Loading loading_msg={"Preview Loading..."} showQuotes={false}></Loading>}
          {!loadingPreviewModal && (
            <video
              style={{
                width: 270,
                height: 480,
              }}
              controls={true}
            >
              <source src={videoData?.src}></source>
            </video>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="primary"
          disabled={loadingPreviewModal}
          onClick={(e) => {
            handleClosePreviewModal();
            handleShowShareModal(false);
            logEvent(analytics, "share_trigger", {
              trigger_type: "share",
              firebase_screen: "Preview Modal - Step 5",
              firebase_screen_class: "PreviewModal",
            });
          }}
        >
          <IosShare sx={{ color: "rgb(232, 236, 241)" }} />
          <span>Share</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
