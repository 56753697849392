import { FC, useState, ChangeEvent, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import "../scss/ShareModal.scss";

import { getAnalytics, logEvent } from "firebase/analytics";
const analytics = getAnalytics();

const urlServer = process.env.REACT_APP_SERVER ? process.env.REACT_APP_SERVER : "http://127.0.0.1:5001/article-to-video-poc/europe-west1/";

export interface ShareModalProps {
  handleCloseShareModal: () => void;
  showShareModal: boolean;
  shareModalVariation: boolean;
  videoToShare: string;
}

export const ShareModal: FC<ShareModalProps> = ({ handleCloseShareModal, showShareModal, shareModalVariation, videoToShare }) => {
  const [email, setEmail] = useState("");
  const [gdprConsent, setGdprConsent] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [gdprError, setGdprError] = useState(false);
  const [formError, setFormError] = useState(false);
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const handleEmailChange = (e: ChangeEvent) => {
    if (e && e.target) {
      setEmailError(false);
      setFormError(false);
      setEmail((e.target as HTMLInputElement).value);
    }
  };

  const handleGdprChange = (e: ChangeEvent) => {
    if (e && e.target) {
      setGdprError(false);
      setFormError(false);
      setGdprConsent((e.target as HTMLInputElement).checked);
    }
  };

  const onSubmitForm = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setFormError(false);
    const isEmail = // regex taken from validated answer here https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        email
      );
    if (gdprConsent && isEmail) {
      logEvent(analytics, "tutorial_end");
      axios({
        method: "post",
        url: `${urlServer}atv/signup`,
        data: JSON.stringify({
          email: email,
        }),
        headers: {
          "Content-Type": "text/plain", //multipart/form-data
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setFormSuccess(true);
            setTimeout(() => {
              handleCloseShareModal();
              setFormSuccess(false);
            }, 3000);
            logEvent(analytics, "generate_lead", {
              lead_type: videoToShare != null ? "video_download" : "feature_upgrade",
            });
          } else {
            setFormError(true);
          }
        })
        .catch((e) => {
          setFormError(true);
        });
    } else {
      if (!gdprConsent) {
        setGdprError(true);
      }
      if (!isEmail) {
        setEmailError(true);
      }
    }
  };

  const downloadVideo = () => {
    setDownloadInProgress(true);
    logEvent(analytics, "tutorial_end");
    axios({
      method: "get",
      url: videoToShare,
      responseType: "blob",
    }).then((response) => {
      const urlObject = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urlObject;
      link.setAttribute("download", `expandto-${new Date().toJSON().slice(0, 10)}.mp4`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadInProgress(false);
    });
  };

  useEffect(() => {
    if (showShareModal) {
      logEvent(analytics, "screen_view", {
        firebase_screen: "Share Modal - Step 6",
        firebase_screen_class: "ShareModal",
      });
    }
  }, [showShareModal]);

  return (
    <Modal show={showShareModal} onHide={handleCloseShareModal}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {formSuccess ? (
          <h2 className="modal-title">Thank you! We will contact you shortly.</h2>
        ) : (
          <form onSubmit={onSubmitForm} className="form-container">
            <h2 className="modal-title">
              {shareModalVariation
                ? "Sign up for early access and generate unlimited variations, longer videos, download in HD and more."
                : "Sign up for early access and download your video in HD, remove the watermark, access style customisation and more."}
            </h2>
            <input type="text" name="email" placeholder="email addresss" value={email} onChange={handleEmailChange} className={emailError ? "error" : ""} />
            <div className="errorContainer">{emailError && <div className="error">Please enter a valid email address.</div>}</div>
            <div className="gdpr-checkbox">
              <input type="checkbox" name="gdpr" className="checkbox" checked={gdprConsent} onChange={handleGdprChange} />
              <div className="gdpr">
                I acknowledge that the information I provide will be processed in accordance with the Privacy Policy. We will use the information you provide on this form to be in
                touch with you and to provide updates and marketing.
              </div>
            </div>
            <div className="errorContainer">{gdprError && <div className="error">Please aggree to receive updates from us.</div>}</div>
            <input type="submit" value={formSuccess ? "Please wait" : "Register"} className={`submit ${formSuccess && "disabled"}`} />
            {!shareModalVariation && (
              <div className="secondary" onClick={() => !downloadInProgress && downloadVideo()}>
                {downloadInProgress ? "please wait..." : "...or download in low quality."}
              </div>
            )}
            <div className="errorContainer">{formError && <div className="error">Something went wrong, please try again later or drop an email to jono@memtell.com</div>}</div>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};
