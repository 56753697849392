import React, { ChangeEvent } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./scss/custom.scss";
import "./scss/App.scss";
import type { Proposition, VideoData, Slide, PropositionFormConfiguration } from "./constants/Interfaces";
import axios from "axios";

// For some unknown reason, reorganising these in another way crashes the app... // TODO: investigate why
import { Editor } from "./components/Editor"; // STEP 4 - Slideshow Editor
import { PreviewModal } from "./components/PreviewModal"; // STEP 5 - Preview Modal from editor
import { ShareModal } from "./components/ShareModal"; // STEP 6 - Share modal to capture leads
import { VideoForm } from "./components/VideoForm"; // STEP 1 - Initial Video Form
import { Loading } from "./components/Loading"; // STEP 2 - Loading screen
import { NavBar } from "./components/NavBar";
import { Results } from "./components/Results"; // STEP 3 - Variations Results page

import "@fontsource/montserrat/900.css"; // Defaults to weight 400.

export const EditorContext = React.createContext({
  //initial_form
  platform: "Instagram",
  based_on: "article",
  uri: "",
  duration_seconds: "30s",
  tone: "fun",
  //audio
  tts_volume: 80,
  music_volume: 10,

  slides: undefined as Slide[] | undefined,
  propositions: [undefined, undefined, undefined] as Proposition[] | undefined[],
  selected: -1 as number,
  updateEditorContext: (state: any, propositionNumber?: number) => {},

  images_assets: [] as { src: string; height: number; width: number; id?: string }[],
});

export const LoadingContext = React.createContext({
  loading: false,
  loading_msg: undefined as string | undefined,
  loading_type: "fullscreen",
  updateLoadingContext: (state: any) => {},
});

const urlServer = process.env.REACT_APP_SERVER ? process.env.REACT_APP_SERVER : "http://127.0.0.1:5001/article-to-video-poc/europe-west1/";

class App extends React.Component<
  {},
  {
    // video form
    platform: string;
    based_on: string;
    uri: string;
    duration_seconds: string;
    tone: string;

    //proposition
    propositions: Proposition[] | undefined[];
    selected: number;

    // audio
    tts_volume: number;
    music_volume: number;

    //slides
    slides: Slide[] | undefined;
    slide_changed: boolean;

    // video
    video_data: VideoData | undefined;

    // loading
    loading: boolean;
    loading_msg: string | undefined;
    loading_type: string;

    // errors
    error_msg: string | undefined;

    //modal
    showPreviewModal: boolean;
    loadingPreviewModal: boolean;

    //modal share
    showShareModal: boolean;
    shareModalVariation: boolean;
    videoToShare: string;

    // assets
    images_assets: { src: string; height: number; width: number; id?: string }[];
    scrapped_images: { src: string; height: number; width: number; id?: string }[];
    titles_assets: string[];
    alternate_text_assets: string[];

    // miscellaneous
    allow_variation_request: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      // initial_form parameters, mostly used in VideoForm
      platform: "Instagram",
      based_on: "article",
      uri: "",
      duration_seconds: "30s",
      tone: "fun",

      //propositions
      // A Proposition contains relevant infos about one slideshow/video variation
      propositions: [undefined, undefined, undefined],
      selected: -1, // Id of currently selected Proposition -1 otherwise
      slide_changed: false, // True if any change was made that should trigger a re-render of the video
      // TODO: move slide_changed to the proposition level

      //audio
      tts_volume: 80, // Text to speech volume // TODO: move to the proposition level
      music_volume: 10, // Background track volume // TODO: move to the proposition level

      // slides
      slides: undefined, // TODO: should not be used anymore, can be deleted (to be tested)
      //video data
      video_data: undefined, // TODO: should not be used anymore, can be deleted (to be tested)

      // loading_context
      loading: false, // true if Loading.tsx content should be displayed
      loading_msg: undefined, // message in the Loading.tsx header
      loading_type: "fullscreen", // Not sure what other options are // TODO: investigate

      //errors
      error_msg: undefined, // TODO: not used at the moment, could be removed or reused in the case of 429. Used to be the error banner at the top of the page

      //previewmodal
      showPreviewModal: false,
      loadingPreviewModal: false, // True if the video to preview is loadings

      //sharemodal
      showShareModal: false,
      shareModalVariation: false, // True/false depending on what text we want to display
      videoToShare: "", // If set, then the download link will use this instead

      //assets
      images_assets: [], // Good images from the page
      scrapped_images: [], // All images from the page
      titles_assets: [], // Titles from the page
      alternate_text_assets: [], // All summaries used in the video

      // miscellaneous
      allow_variation_request: false, // True if we're ready to accept more variations request and display corresponding buttons
      // Not used ATM because Share Modal takes over
    };
  }

  // update contexts callbacks // !make sure to add a key to the list when needed otherwise changes will be discarded
  updateEditorContext = (state: any, propositionNumber?: number) => {
    for (let key of Object.keys(state)) {
      if (
        [
          "platform",
          "based_on",
          "uri",
          "duration_seconds",
          "tone",
          "tts_volume",
          "music_volume",
          "slides",
          "selected",
          "images_assets",
          "currentSlide",
          "video_data",
          "propositions",
        ].indexOf(key) === -1
      ) {
        delete state[key];
      }
    }
    if (Object.keys(state).length > 0) {
      // If we provided a propositionNumber, it means we want to edit the state of that proposition specifially
      if (propositionNumber !== undefined) {
        const newPropositions = [...this.state.propositions] as Proposition[];
        const newProposition = { ...this.state.propositions[propositionNumber], ...state };
        newPropositions[propositionNumber] = newProposition as Proposition;
        this.setState({ propositions: newPropositions, slide_changed: true }, this.logState);
      } else {
        this.setState({ ...state, slide_changed: true }, this.logState);
      }
    }
  };

  updateLoadingContext = (state: any) => {
    for (let key of Object.keys(state)) {
      if (["loading", "loading_msg"].indexOf(key) === -1) {
        delete state[key];
      }
    }
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  };

  /* debug logs */
  logState = () => {
    if (process.env.NODE_ENV === "development") console.log("this.logState", this.state);
  };

  // given a string and a state, sets state to string. Used with HeadlessSelect
  handleChangeSelect = (value: string, state: string) => {
    let stateObject = {} as any;
    stateObject[state] = value;
    this.setState(stateObject);
  };

  handleChangeUri = (event: ChangeEvent) => {
    event.preventDefault();
    this.setState({ uri: (event.target as any).value }, this.logState);
  };

  /** onSubmit events **/
  // Sends slides, gets video back
  requestVideo = async (slides: Slide[], tts_volume: number, music_volume: number, additonal_parameters: any = {}) => {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const response = await axios({
          method: "post",
          url: `${urlServer}atv/renderVideo`,
          data: JSON.stringify({
            slides: slides,
            audio: { tts_volume: tts_volume, music_volume: music_volume },
            backgroundTrackId: this.state.propositions[this.state.selected]?.video_data?.backgroundTrackId,
            ...additonal_parameters,
          }),
          headers: {
            "Content-Type": "text/plain", //multipart/form-data
          },
        });
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject({ status: response.status, message: response.statusText });
        }
      } catch (error: any) {
        reject(error);
      }
    });
  };

  // Sends form parameters from VideoForm.tsx, gets slides back from GPT
  requestSlides = async (initialFormGetParams: any, images: any, headingsArray: any, additonal_parameters: any) => {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const response = await axios.get(`${urlServer}atv/retreiveSlides`, {
          params: {
            ...initialFormGetParams,
            images,
            headingsArray,
            ...additonal_parameters,
          },
        });
        if (response.status === 200) {
          resolve(
            (response.data?.slides || []).map((slide: any, index: number) => {
              slide.id = `${index}-${new Date().getTime()}`;
              return slide;
            })
          );
        } else {
          reject({ status: response.status, message: response.statusText });
        }
      } catch (error: any) {
        reject(error);
      }
    });
  };

  // Based on VideoForm.tsx params and scrapped data, generate 3 different Propositions (slides + video) including a Pexel only one
  requestProposition = async (proposition_nbr: number, initial_form_data: any, images: any, headingsArray: any, individual_specifications: any) => {
    return new Promise(async (resolve, reject) => {
      this.setState({
        // loading: true,
        loading_msg: `Writing a storyboard from your awesome content…`,
        error_msg: undefined,
      });
      try {
        // console.log("building slide for #" + proposition_nbr);
        let slides: any = await this.requestSlides(initial_form_data, images, headingsArray, individual_specifications);
        // console.log("slide #" + proposition_nbr, slides);

        this.setState({
          // loading: true,
          loading_msg: `Shooting the video in our studios…`,
          error_msg: undefined,
        });

        try {
          // console.log("building video for #" + proposition_nbr);
          let video_data = await this.requestVideo(slides, this.state.tts_volume, this.state.music_volume);
          // console.log("slide #" + proposition_nbr, video_data);
          resolve({ slides, video_data, currentSlide: 0 });
        } catch (error: any) {
          // console.log("Catching exception for the video try");
          if (typeof error === "object" && "status" in error) {
            reject(
              `an error [${error.status}] : "${error.statusText}" happend during the video generation for proposition #${proposition_nbr}. Try again later or contact your administrator`
            );
          } else {
            reject(`an error happend during the video generation for proposition #${proposition_nbr}. Try again later or contact your administrator`);
          }
        }
      } catch (error: any) {
        // console.log("Catching exception for the slide try");
        if (typeof error === "object" && "status" in error) {
          reject(
            `an error [${error.status}] : "${error.statusText}" happend during the slide generation for proposition #${proposition_nbr}. Try again later or contact your administrator`
          );
        } else {
          reject(`an error happend during the slide generation for proposition #${proposition_nbr}. Try again later or contact your administrator`);
        }
      }
    });
  };

  // Called by VideoForm or Results to generate new variations
  handleInitialForm = async (
    event: any,
    configuration: PropositionFormConfiguration = {
      start_index: 0,
      proposition_amount: 3,
      individual_specifications: [{}, { random_pexel: true }, { alternate_summary: true }],
      do_scrap: true,
    },
    initial_loading_type: string = "fullscreen"
  ) => {
    const initialFormGetParams = {
      platform: this.state.platform,
      based_on: this.state.based_on,
      uri: encodeURIComponent(this.state.uri),
      duration_seconds: parseInt(this.state.duration_seconds.split("s")[0]),
      tone: this.state.tone,
    };
    // console.log("requesting propositions", configuration);
    // console.group();

    const state_propositions = this.state.propositions;
    if (this.state.propositions.length < configuration.start_index + configuration.proposition_amount) {
      state_propositions.length = configuration.start_index + configuration.proposition_amount;
      for (let i = configuration.start_index; i < state_propositions.length; i = i + 1) {
        if (!state_propositions[i]) {
          state_propositions[i] = undefined;
        }
      }
    }

    // console.log("is this a retry = ", configuration.retry);
    if (configuration.retry === true) {
      const end_index = configuration.start_index + configuration.proposition_amount;
      for (let i = configuration.start_index; i < end_index; i = i + 1) {
        state_propositions[i] = undefined;
      }
    }
    // get scrapped data
    // console.log(configuration.do_scrap ? "get scrapped data" : "reload_scraped value");
    this.setState({
      loading: true,
      loading_msg: "Collecting images and texts from your link…",
      loading_type: initial_loading_type,
      error_msg: undefined,
      propositions: state_propositions,
      allow_variation_request: false,
    });
    try {
      const scrapResponse = configuration.do_scrap
        ? await axios.get(`${urlServer}atv/scrapData`, { params: initialFormGetParams })
        : { data: { images: this.state.scrapped_images, headingsArray: this.state.titles_assets } };
      const { images, nonSelectedImages, headingsArray } = scrapResponse.data;

      if (configuration.do_scrap) {
        this.setState({
          images_assets: [...(images || []), ...(nonSelectedImages || [])],
          scrapped_images: images,
          titles_assets: headingsArray,
        });
      }
      let internal_specification_index = 0;
      // console.group();
      for (let index = configuration.start_index; index < state_propositions.length; index = index + 1) {
        try {
          // getting proposition
          const individual_specifications =
            configuration.individual_specifications.length > internal_specification_index ? configuration.individual_specifications[internal_specification_index] : {};
          internal_specification_index = internal_specification_index + 1;
          // console.log(`Requesting Proposition ${index + 1}`);
          // console.group();
          const proposition: any = await this.requestProposition(index + 1, initialFormGetParams, images, headingsArray, individual_specifications);
          // console.log(proposition);
          proposition.video_data.internal_specification = individual_specifications;
          state_propositions[index] = proposition;
          this.setState({ propositions: state_propositions });
          // console.log(`Proposition ${index + 1}`, proposition);

          // feeding the alternate texts
          const alternate_text_assets = new Set();
          for (let proposition of state_propositions) {
            if (proposition) {
              for (let slide of proposition.slides) {
                alternate_text_assets.add(slide.summary);
              }
            }
          }
          this.setState({ alternate_text_assets: Array.from(alternate_text_assets) as string[] });

          // changing the loading type to internal to escape the fullscreen
          if (proposition) {
            this.setState({ loading_type: "internal" });
          }
          // console.groupEnd();
        } catch (error: any) {
          console.log("Catching exception for the proposition try", error);
          // console.groupEnd();
          const individual_specifications =
            configuration.individual_specifications.length > internal_specification_index - 1 ? configuration.individual_specifications[internal_specification_index - 1] : {};

          state_propositions[index] = { slides: [], video_data: { src: "", width: "", height: "", backgroundTrackId: "" }, currentSlide: 0, individual_specifications };
          this.setState({
            propositions: state_propositions,
            error_msg: typeof error === "string" ? error : "Un unexpected error happend",
          });
        }
      }
      // console.groupEnd();
      this.setState({ loading: false, loading_msg: undefined, loading_type: "fullscreen", allow_variation_request: true });
    } catch (error: any) {
      // console.log("Catching exception for the scrapping try");
      this.setState({
        loading: false,
        loading_msg: undefined,
        loading_type: "fullscreen",
        error_msg: "An unexpected error happend, check your internet connexion and try again later",
        allow_variation_request: true,
      });
    }
    // console.groupEnd();
  };

  // Called by Preview to (re)-generate one video from a Proposition
  handleGeneratingVideo = async (event: any) => {
    if (event) {
      event.preventDefault();
    }
    if (this.state.slide_changed === false) {
      this.setState({ showPreviewModal: true });
      return;
    }
    // console.log("calling render video");
    this.setState({
      slide_changed: false,
      loadingPreviewModal: true,
      showPreviewModal: true,
      error_msg: undefined,
    });
    // console.log("Video_data before post");
    // console.log(this.state.propositions[this.state.selected]?.video_data);
    axios({
      method: "post",
      url: `${urlServer}atv/renderVideo`,
      data: JSON.stringify({
        slides: this.state.propositions[this.state.selected]?.slides,
        audio: { tts_volume: this.state.tts_volume, music_volume: this.state.music_volume },
        backgroundTrackId: this.state.propositions[this.state.selected]?.video_data?.backgroundTrackId,
      }),
      headers: {
        "Content-Type": "text/plain", //multipart/form-data
      },
    })
      .then((res) => {
        if (res.status === 200) {
          // console.log(res.data);
          const newPropositions = [...this.state.propositions] as Proposition[];
          const newProposition = { ...newPropositions[this.state.selected] } as Proposition;
          newProposition.video_data = res.data;
          newPropositions[this.state.selected] = newProposition;
          this.setState({
            loadingPreviewModal: false,
            propositions: newPropositions,
          });
        } else if (res.status >= 300) {
          this.setState({
            loadingPreviewModal: false,
            slide_changed: true,
            error_msg: `an error [${res.status}] : ${res.statusText} happend during the video compilation. contact your administrator`,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loadingPreviewModal: false,
          slide_changed: true,
          error_msg: "an error happend during the video compilation. contact your administrator",
        });
      });
  };

  handleClosePreviewModal = () => {
    this.setState({
      showPreviewModal: false,
    });
  };

  handleCloseShareModal = () => {
    this.setState({
      showShareModal: false,
    });
  };

  handleShowShareModal = (variation: boolean, url?: string) => {
    this.setState({
      showShareModal: true,
      shareModalVariation: variation,
      videoToShare: url != null ? url : this.state.propositions[this.state.selected]?.video_data.src || "",
    });
  };

  haveValidProposition = () => {
    let hasValidProposition = false;
    for (const proposition of this.state.propositions) {
      hasValidProposition = hasValidProposition || proposition !== undefined;
    }
    return hasValidProposition;
  };
  // render timeline est le truc de drag and drop
  render() {
    return (
      <LoadingContext.Provider
        value={{ loading: this.state.loading, loading_msg: this.state.loading_msg, loading_type: this.state.loading_type, updateLoadingContext: this.updateLoadingContext }}
      >
        <EditorContext.Provider
          value={{
            platform: this.state.platform,
            based_on: this.state.based_on,
            uri: this.state.uri,
            duration_seconds: this.state.duration_seconds,
            tone: this.state.tone,
            tts_volume: this.state.tts_volume,
            music_volume: this.state.music_volume,
            slides: this.state.slides,
            propositions: this.state.propositions,
            selected: this.state.selected,
            updateEditorContext: this.updateEditorContext,
            images_assets: this.state.images_assets,
          }}
        >
          <LoadingContext.Consumer>
            {(loading_context) => (
              <EditorContext.Consumer>
                {(editor_context) => (
                  <>
                    {!loading_context.loading && <NavBar />}
                    {/* {this.state.error_msg && <div className="error">{this.state.error_msg}</div>} */}
                    <ShareModal
                      handleCloseShareModal={this.handleCloseShareModal}
                      showShareModal={this.state.showShareModal}
                      shareModalVariation={this.state.shareModalVariation}
                      videoToShare={this.state.videoToShare}
                    />
                    <PreviewModal
                      handleClosePreviewModal={this.handleClosePreviewModal}
                      showPreviewModal={this.state.showPreviewModal}
                      videoData={this.state.propositions[this.state.selected]?.video_data}
                      loadingPreviewModal={this.state.loadingPreviewModal}
                      handleShowShareModal={this.handleShowShareModal}
                    />
                    {loading_context.loading && loading_context.loading_type === "fullscreen" && <Loading loading_msg={loading_context.loading_msg} showQuotes={true}></Loading>}
                    {!loading_context.loading && !this.haveValidProposition() && !editor_context.slides && (
                      <VideoForm
                        handleChangeSelect={this.handleChangeSelect}
                        platform={this.state.platform}
                        based_on={this.state.based_on}
                        uri={this.state.uri}
                        handleChangeUri={this.handleChangeUri}
                        duration_seconds={this.state.duration_seconds}
                        tone={this.state.tone}
                        handleSubmitInitialForm={this.handleInitialForm}
                        handleShowShareModal={this.handleShowShareModal}
                      />
                    )}
                    {(!loading_context.loading || loading_context.loading_type !== "fullscreen") && editor_context.selected > -1 && (
                      <Editor
                        generateVideoCallback={this.handleGeneratingVideo}
                        titles={this.state.titles_assets}
                        alternate_texts={this.state.alternate_text_assets}
                        images={this.state.images_assets}
                        handleShowShareModal={this.handleShowShareModal}
                      />
                    )}

                    {(!loading_context.loading || loading_context.loading_type !== "fullscreen") &&
                      !editor_context.slides &&
                      this.haveValidProposition() &&
                      editor_context.selected === -1 && (
                        <Results
                          propositions={this.state.propositions}
                          article={this.state.uri}
                          channel={this.state.platform}
                          type={this.state.based_on}
                          duration_seconds={this.state.duration_seconds}
                          tone={this.state.tone}
                          onMoreVariation={this.handleInitialForm}
                          allow_variation_request={this.state.allow_variation_request}
                          handleShowShareModal={this.handleShowShareModal}
                        />
                      )}
                  </>
                )}
              </EditorContext.Consumer>
            )}
          </LoadingContext.Consumer>
        </EditorContext.Provider>
      </LoadingContext.Provider>
    );
  }
}

export default App;
