import { FC, useState } from "react";
import { NavBar } from "../components/NavBar";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../scss/custom.scss";
import "../scss/Login.scss";

export const Login: FC = () => {
  const [email, changeEmail] = useState("");
  const [pwd, changePwd] = useState("");
  const [error, setError] = useState(false);

  return (
    <>
      <NavBar />
      <div className="login-container">
        <div className="login-box">
          <h1>Welcome back</h1>
          <div className="inputs">
            <input type="text" value={email} onChange={(e) => e && e.target && changeEmail(e.target.value)} placeholder="Email" />
            <input type="password" value={pwd} onChange={(e) => e && e.target && changePwd(e.target.value)} placeholder="Password" />
            {error && <div className="error">Please verify your username and password.</div>}
          </div>
          <input type="submit" value="Log in" className="submit" onClick={() => setError(true)} />
        </div>
      </div>
    </>
  );
};
