import { type FC, useContext } from "react";
import { useRef } from "react";
import { EditorContext } from "../App";

import "@fontsource/montserrat"; // Defaults to weight 400.
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

export interface TextProps {
  items: any;
  height: number;
  type: string;
  onChangeMode: (mode: string, type: string) => void;
  format: string;
}

export const TextList: FC<TextProps> = ({ onChangeMode, items, height, type, format }) => {
  const editor_context = useContext(EditorContext);
  const proposition = editor_context.propositions[editor_context.selected];
  const scrollElement = useRef<HTMLDivElement>(null);

  function sectionTitle() {
    let title = "";
    if (type === "text") title = "Alternate text ideas";
    else if (type === "title") title = "Titles from your page";
    return title;
  }

  function scrollHorizontal(position: string) {
    if (scrollElement?.current) {
      const before = scrollElement.current.scrollLeft;
      let offsetWidth = 0;
      if (position === "left") {
        offsetWidth -= scrollElement.current.offsetWidth;
      } else {
        offsetWidth += scrollElement.current.offsetWidth;
      }
      scrollElement.current.scrollLeft += offsetWidth;
      if (position === "right" && before === scrollElement.current.scrollLeft) {
        onChangeMode("see_all", type);
      }
    }
  }

  const onClickItem = (item: string) => {
    // console.log(item);
    const slide = proposition?.slides && proposition?.slides.length > proposition.currentSlide ? proposition.slides[proposition.currentSlide] : undefined;
    if (slide) {
      slide.summary = item;
      editor_context.updateEditorContext({ slides: proposition?.slides }, editor_context.selected);
    }
  };

  function renderHorizontal() {
    const list = items.map((item: any, index: any) => {
      return (
        <div className="item" key={index}>
          <div className="textContainer">
            <div className="mb-0 box">
              <span
                className={item.length >= 0 && item.length <= 29 ? "font-lg" : item.length >= 30 && item.length <= 69 ? "font-md" : "font-sm"}
                onClick={() => {
                  onClickItem(item);
                }}
              >
                {item}
              </span>
            </div>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="title">
          <div className="categorie">{sectionTitle()}</div>
          <div
            className="seeall"
            onClick={() => {
              onChangeMode("see_all", type);
            }}
          >
            see all
          </div>
        </div>

        <div className="container-horizontal" ref={scrollElement} style={{ height: height + "px" }}>
          <div
            onClick={() => {
              scrollHorizontal("left");
            }}
            className="arrow arrow-left"
            style={{ height: height + "px" }}
          >
            <span>
              <ArrowBackIos sx={{ color: "rgb(232, 236, 241)", fontSize: "28px" }} />
            </span>
          </div>
          {list}
          <div
            onClick={() => {
              scrollHorizontal("right");
            }}
            className="arrow arrow-right"
            style={{ height: height + "px" }}
          >
            <span>
              <ArrowForwardIos sx={{ color: "rgb(232, 236, 241)", fontSize: "28px" }} />
            </span>
          </div>
        </div>
      </>
    );
  }

  function renderVertical() {
    const list = items.map((item: any, index: any) => {
      return (
        <div className="item-text" key={index}>
          <div className="textContainer">
            <div className="mb-0 box">
              <span
                onClick={() => {
                  onClickItem(item);
                }}
                className={item.length >= 0 && item.length <= 29 ? "font-lg" : item.length >= 30 && item.length <= 69 ? "font-md" : "font-sm"}
              >
                {item}
              </span>
            </div>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="text-list-vertical">{list}</div>
      </>
    );
  }

  return <>{format === "horizontal" ? renderHorizontal() : renderVertical()}</>;
};
