import { useContext, useState, useEffect } from "react";
import type { FC } from "react";

import "../scss/EditorLeft.scss";
import {
  AlignVerticalTop,
  AlignVerticalCenter,
  AlignVerticalBottom,
  ArrowBackIos,
  ArrowForwardIos,
  Crop as CropIcon,
  ZoomOutMap,
  ZoomInMap,
  Delete,
  Done,
} from "@mui/icons-material";
import { WorkingSlide } from "./WorkingSlide";
import { EditorContext } from "../App";
import { FakeCrop } from "./Crop";
import { CropData } from "../constants/Interfaces";

const alignmentTable: any = {
  start: "30%",
  center: "50%",
  end: "70%",
  "30%": "start",
  "50%": "center",
  "70%": "end",
  null: "center",
  undefined: "center",
};

export const ToolBarMode = {
  Default: "default",
  Text: "text",
  Background: "background",
};

export const EditMode = {
  Default: "default",
  Crop: "crop",
};

const hiddenStyle: React.CSSProperties = { display: "none" };
const defaultStyle: React.CSSProperties = { cursor: "pointer" };

export const EditorLeft: FC = () => {
  const editor_context = useContext(EditorContext);
  const proposition = editor_context.propositions[editor_context.selected];
  const [arrowVisibilityLeft, setArrowVisibilityLeft] = useState(proposition?.currentSlide === 0 ? hiddenStyle : defaultStyle);
  const [arrowVisibilityRight, setArrowVisibilityRight] = useState((proposition?.currentSlide || 0) + 1 >= (proposition?.slides || []).length ? hiddenStyle : defaultStyle);
  const [activeAlignment, setActiveAlignement] = useState(
    proposition?.slides && proposition?.slides.length > proposition.currentSlide ? alignmentTable[`${proposition.slides[proposition.currentSlide].alignment}`] : "center"
  );
  const [fitMode, setFitMode] = useState(
    proposition?.slides && proposition?.slides.length > proposition.currentSlide ? proposition?.slides[proposition.currentSlide].fit : "cover"
  );
  const [toolbarMode, setToolbarMode] = useState(ToolBarMode.Default);
  const [editMode, setEditMode] = useState(EditMode.Default);
  const [cropData, setCropData] = useState(proposition?.slides && proposition?.slides.length > proposition.currentSlide ? proposition?.slides[proposition.currentSlide].crop : {});

  useEffect(() => {
    const slide = proposition?.slides && proposition?.slides.length > proposition.currentSlide ? proposition.slides[proposition.currentSlide] : undefined;
    setArrowVisibilityLeft(proposition?.currentSlide === 0 ? hiddenStyle : defaultStyle);
    setArrowVisibilityRight((proposition?.currentSlide || 0) + 1 >= (proposition?.slides || []).length ? hiddenStyle : defaultStyle);
    setActiveAlignement(alignmentTable[`${slide?.alignment}`]);
    setFitMode(slide?.fit || "cover");
  }, [editor_context, proposition?.currentSlide, proposition?.slides]);

  const clickRight = () => {
    editor_context.updateEditorContext({ currentSlide: (proposition?.currentSlide || 0) + 1 }, editor_context.selected);
  };

  const clickLeft = () => {
    editor_context.updateEditorContext({ currentSlide: (proposition?.currentSlide || 0) - 1 }, editor_context.selected);
  };

  const onChangeAlignment = (event: any, position: string) => {
    event.preventDefault();
    event.stopPropagation();
    if (proposition?.slides) {
      setActiveAlignement(position);
      proposition.slides[proposition.currentSlide].alignment = alignmentTable[position];
      editor_context.updateEditorContext({ slides: proposition?.slides }, editor_context.selected);
    }
  };

  const onChangeFit = (fit: string) => {
    if (proposition?.slides) {
      setFitMode(fit);
      proposition.slides[proposition?.currentSlide].fit = fit;
      editor_context.updateEditorContext({ slides: proposition?.slides }, editor_context.selected);
    }
  };

  const updateToolBarMode = (mode: string) => {
    setToolbarMode(mode);
  };

  const onClearBackground = () => {
    const slide = proposition?.slides && proposition?.slides.length > proposition.currentSlide ? proposition.slides[proposition.currentSlide] : undefined;
    if (slide) {
      slide.image = "";
      slide.crop = undefined;
      editor_context.updateEditorContext({ slides: proposition?.slides }, editor_context.selected);
    }
  };

  const handleDeleteClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const slides = proposition?.slides;
    if ((slides || []).length > 1) {
      slides?.splice(proposition?.currentSlide || 0, 1);
      const new_state: any = { slides: slides };
      const slides_length = (slides || []).length;
      if ((proposition?.currentSlide || 0) >= slides_length) {
        new_state.selected = slides_length - 1 >= 0 ? slides_length - 1 : 0;
      }
      editor_context.updateEditorContext(new_state, editor_context?.selected);
    }
  };

  const onCrop = (crop: any) => {
    const slide = proposition?.slides && proposition?.slides.length > proposition.currentSlide ? proposition.slides[proposition.currentSlide] : undefined;
    if (slide) {
      // slide.crop = crop;
      // editor_context.updateEditorContext({ slides: editor_context.slides });
      setCropData(crop);
      // console.log(crop)
    }
  };

  const onApplyCrop = () => {
    const slide = proposition?.slides && proposition?.slides.length > proposition.currentSlide ? proposition.slides[proposition.currentSlide] : undefined;
    if (slide) {
      slide.crop = "left" in (cropData || {}) ? (cropData as CropData) : undefined;
      editor_context.updateEditorContext({ slides: proposition?.slides }, editor_context?.selected);
    }
  };

  const slide = proposition?.slides && proposition?.slides.length > proposition.currentSlide ? proposition.slides[proposition.currentSlide] : undefined;
  return (
    <>
      {editMode === EditMode.Crop && (
        <div className="side-left cropbg">
          <div className="toolbar crop">
            <div className="controls">
              <div className="box" style={{ zIndex: 1 }}>
                <div
                  className="validate-crop"
                  onClick={() => {
                    setEditMode(EditMode.Default);
                    onApplyCrop();
                  }}
                >
                  <Done sx={{ color: "rgb(232, 236, 241)", fontSize: "16px" }} />
                  <span>Apply</span>
                </div>
                {/* <Button className="btn-validate" variant="success" >Apply</Button> */}
              </div>
            </div>
          </div>

          <div className="slide">
            <div className="slide-open">{slide && <FakeCrop image={slide.image} onCrop={onCrop} initialLeft={slide.crop?.left}></FakeCrop>}</div>
          </div>
        </div>
      )}

      {editMode === EditMode.Default && (
        <div
          className="side-left"
          onClick={(event: any) => {
            event.preventDefault();
            event.stopPropagation();
            updateToolBarMode(ToolBarMode.Default);
          }}
        >
          <div
            className="toolbar"
            style={{ zIndex: 1 }}
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              // catching the click on event on toolbar so it doesn't change back to default because of the side-left click logic
            }}
          >
            <div className={"controls " + (toolbarMode === ToolBarMode.Default ? "end" : "")}>
              {toolbarMode === ToolBarMode.Text && (
                <div className="box" style={{ zIndex: 1 }}>
                  <AlignVerticalTop
                    sx={{
                      color: "rgb(71, 89, 108)",
                      cursor: "pointer",
                      opacity: activeAlignment === "start" ? 1 : 0.5,
                    }}
                    onClick={(event) => onChangeAlignment(event, "start")}
                  />
                  <AlignVerticalCenter
                    sx={{
                      color: "rgb(71, 89, 108)",
                      cursor: "pointer",
                      opacity: activeAlignment === "center" ? 1 : 0.5,
                    }}
                    onClick={(event) => onChangeAlignment(event, "center")}
                  />
                  <AlignVerticalBottom
                    sx={{
                      color: "rgb(71, 89, 108)",
                      cursor: "pointer",
                      opacity: activeAlignment === "end" ? 1 : 0.5,
                    }}
                    onClick={(event) => onChangeAlignment(event, "end")}
                  />
                </div>
              )}
              {toolbarMode === ToolBarMode.Background && (
                <div className="box" style={{ zIndex: 1 }}>
                  <div className="icon-text-group" onClick={onClearBackground}>
                    <Delete sx={{ color: "rgb(71, 89, 108)" }} /> Clear Background
                  </div>
                  <div
                    className={`icon-text-group ${fitMode === "contain" ? "disabled" : ""}`}
                    onClick={() => {
                      fitMode === "cover" && setEditMode(EditMode.Crop);
                    }}
                  >
                    <CropIcon sx={{ color: "rgb(71, 89, 108)" }} /> Crop
                  </div>
                  {fitMode === "contain" && (
                    <div
                      className="icon-text-group"
                      onClick={() => {
                        onChangeFit("cover");
                      }}
                    >
                      <ZoomOutMap sx={{ color: "rgb(71, 89, 108)" }} /> Cover
                    </div>
                  )}
                  {fitMode === "cover" && (
                    <div
                      className="icon-text-group crop"
                      onClick={() => {
                        onChangeFit("contain");
                      }}
                    >
                      <ZoomInMap sx={{ color: "rgb(71, 89, 108)" }} /> Fit
                    </div>
                  )}
                </div>
              )}
              {toolbarMode === ToolBarMode.Default && (
                <div className="box" style={{ justifyContent: "flex-end" }}>
                  <div className="icon-text-group" onClick={handleDeleteClick}>
                    <Delete
                      sx={{
                        color: "rgb(71, 89, 108)",
                        cursor: "pointer",
                        zIndex: 1,
                      }}
                    />{" "}
                    Delete Slide
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="slide">
            <div className="arrowleft">
              <ArrowBackIos sx={{ color: "rgb(232, 236, 241)", ...arrowVisibilityLeft }} onClick={clickLeft} />
            </div>
            <div className="slide-open">
              <WorkingSlide updateToolBarMode={updateToolBarMode} />
            </div>
            <div className="arrowright">
              <ArrowForwardIos sx={{ color: "rgb(232, 236, 241)", ...arrowVisibilityRight }} onClick={clickRight} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
